<template>
  <div class="search">
    <div class="search-all">
    <div class="search-box flex-between">
      <div class="left" @click="$router.go(-1)"></div>
      <div class="center">热搜结果</div>
      <div class="right"></div>
    </div>
    <div class="section">
      <div class="top">
        <p class="top-user" v-if="hotBloggers.length>0">为你找到关于“{{$route.query.name}}”相关的博主</p>
        <div class="user-list flex">
          <router-link tag="div" :to="{path:'/bloggerDetails',query: { id: item.id }}" class="header" v-for="item in hotBloggers" :key="item.id" >
            <ImgDecypt class="header-img" :src="item.cover"/>
            <div class="top-left flex-around"><span class="left-header"></span><span class="num ellipsis">{{item.fanss}}</span></div>
            <div class="bottom-left flex"><span class="h-img"><ImgDecypt :round="true" class="user-header" :src="item.avatarUrl"/></span><span class="name ellipsis">{{item.nickName}}</span></div>
          </router-link>
        </div>
        <p class="top-user">为你找到关于“{{$route.query.name}}”相关的视频</p>
      </div>
      <div class="bottom">
        <div class="bottom-title flex">
          <div class="hy" :class="{active:switchPage}" @click="hy">会员</div>
          <div class="money" :class="{active:!switchPage}" @click="jb">金币</div>
        </div>
        <div class="video-num">{{total}}条</div>
      </div>
      <div class="list-v">
        <transition name="slide-r">
          <div class="video-list" v-if="switchPage">
            <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="videoList.length>0">
              <videoListsec :item="item" v-for="item in videoList" :key="item.id"/>
            </pullrefresh>
            <van-loading type="spinner" color="#f21313" v-show="videoList&&videoList.length==0 && !noData"/>
            <nodata v-show="noData"/>
          </div>
        </transition>
        <transition name="slide-l">
          <div class="video-list" v-if="!switchPage">
            <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="videoList.length>0">
              <videoListsec :item="item" v-for="item in videoList" :key="item.id"/>
            </pullrefresh>
            <van-loading type="spinner" color="#f21313" v-show="videoList&&videoList.length==0 && !noData"/>
            <nodata v-show="noData"/>
          </div>
        </transition>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { optRecord } from "@/utils/optLog.js";
import pullrefresh from "@/components/PullRefresh/index.vue";
import videoListsec from '../widget/videoListsec.vue';
import {getBloggers,getMedias} from '@/api/app.js';
export default {
  components:{
    videoListsec,
    pullrefresh
  },
  data(){
    return{
      switchPage:true,
      hotBloggers:[],//相关博主列表
      videoList:[],//相关视频列表
      pageNum:1,//页码
      noData:false,//暂无数据
      total:0,//总共视频条数
    }
  },
  created(){
    optRecord(2, undefined, this.$route.query.name);
    this.getBloggers();
    this.getMedias(true);
  },
  methods:{
    async getMedias(bool){//相关视频列表
      let req = {
        bType:1,//0：占位符 1:原创（原创就是视频，色区就是动态） 2:一般av类型 4：广告 3:基本不用
        mType:2,//2:视频 1:原创 0:占位符 3:粉丝福利 4:动态帖子
        content:this.$route.query.name,
        isVip:bool,
        userInput:true,
        pageNum:this.pageNum,
        pageSize:10,
      }
      let ret = await this.$Api(getMedias,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        this.total = ret.data.total;
        let list = ret.data.mediaInfos?ret.data.mediaInfos:[]
        this.videoList = this.videoList.concat(list);
        if(ret.data.hasNext){
          this.$refs.pull.finished = false;
        }else{
          this.$refs.pull.finished = true;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    async getBloggers(){//相关博主列表
      let req = {
        content:this.$route.query.name,
        pageNum:1,
        pageSize:50,
      }
      let ret = await this.$Api(getBloggers,req);
      if(ret.code == 200){
        let list = ret.data.bloggers ? ret.data.bloggers : [];
        this.hotBloggers = list;
      }
    },
    onLoad(){
      this.pageNum ++;
      if(this.switchPage){
        this.getMedias(true);
      }else{
        this.getMedias(false);
      }
    },
    hy(){
      if(!this.switchPage){
        this.switchPage = true;
        this.noData = false;
        this.videoList = [];
        this.pageNum = 1;
        this.getMedias(true);
      }
    },
    jb(){
      if(this.switchPage){
        this.switchPage = false;
        this.noData = false;
        this.videoList = [];
        this.pageNum = 1;
        this.getMedias(false);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
  height:30px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:0 10px 10px;
  box-sizing: border-box;
}
.top-user{
  margin:20px 0 6px 0;
}
.user-list{
  overflow-x:auto;
}
.user-list>div{
  flex-shrink: 0;
}
.header{
  width:132px;
  height:110px;
  border-radius:6px;
  overflow: hidden;
  font-size:0;
  position: relative;
  margin-right:6px;
}
.header-img{
  width:100%;
  height:100%;
}
.top-left{
  position: absolute;
  width:40px;
  height: 12px;
  top:6px;
  left:6px;
  align-items: center;
  background:rgba($color: #000000, $alpha: 0.5);
  border-radius:2px;
}
.num{
  color:#ffffff;
  font-size:8px;
}
.left-header{
  width:8px;
  height:8px;
  background:url('../../assets/png/fan_header.png') center center no-repeat;
  background-size:100%;
}
.bottom-left{
  position:absolute;
  width:100%;
  bottom:0;
  left:0;
  align-items: center;
  height:50px;
  background:linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.6));
}
.user-header{
  width:26px;
  height:26px;
  vertical-align: middle;
  border-radius:50%;
  border:1px solid #F21313;
}
.h-img{
  display: inline-block;
  border-radius: 50%;
  font-size:0;
  border: 1px solid #FFEA5E;
  margin-right: 4px;
  margin-left:6px;
}
.name{
  font-size:10px;
  color:#ffffff;
  flex:1;
}
.bottom{
  position:sticky;
  top:0;
  z-index:6;
  background:#fff;
}
.bottom-title{
  padding-top:10px;
  height:34px;
  border-bottom:1px solid rgba(0,0,0,0.1);
  font-size:20px;
}
.hy{
  margin-right:41px;
}
.active{
  color:$vermillion;
  font-weight:bold;
  position: relative;
}
.active::after{
  position:absolute;
  display: inline-block;
  content:'';
  width:26px;
  height:2px;
  left:50%;
  margin-left:-13px;
  background: $vermillion;
  border-radius: 1px;
  bottom:0;
}
.video-num{
  position:absolute;
  right:0;
  bottom:-16px;
  color:$greyishBrownTwo;
  font-size:10px;
}
.list-v{
  position: relative;
}
.video-list{
  margin-top:16px;
  position: absolute;
  width:100%;
  padding-bottom:30px;
}
</style>
